import { technos } from "../enums/technos";
import { o } from "../service/outils";

export enum technoState {
  PREVIEW,
  NOT_AVAILABLE,
  ASK
}

export class Techno{
    id : number;
    name : string;
    back : boolean ;
    front : boolean;
    state : technoState;
    excludeService : boolean = true;
    keywords : string;

    displayInfo : {
        name? : string;//can be different from this.name : ex :
        // this.name : "node-js"=> sert pour les urls
        // this.displayInfos.name : "node.js - ts" => sert pour l'affichage
        description? : string,
        picture?: string;
        categories? : ('back'|'front'|'db'|'workflow'|'cms')[]
    } = {
        categories : []
    }
    versionsAvailable : string[] = [];

    constructor({id, back, front, name, state, excludeService, keywords, displayInfo} : {id, back, front, name?, state?, excludeService?, keywords?, displayInfo?: Techno['displayInfo']}){
        this.id = id;
        this.back = back;
        this.front = front;
        this.name = name;
        this.state = state;
        this.excludeService = excludeService;
        this.keywords = keywords;
        this.displayInfo = displayInfo || this.displayInfo;
        this.setCategory();
    }


    setFromDefault(){
        const defaultTechno = defaultTechnos.get(this.id);
        o.deepCopy(defaultTechno, this);
    }

    setCategory(){
        if(!this.displayInfo.categories){
            this.displayInfo.categories = [];
        }
        if(this.back){
            this.displayInfo.categories.push('back')
        }
        if(this.front){
            this.displayInfo.categories.push('front')
        }
    }

    static constructFromDb(dbInfos : {id, back, front}) : Techno{
        let result : Techno;
        switch(dbInfos.id){
            case technos.NODETS:
                result = new NodeTechno(dbInfos);
                break;

            default:
                result = new Techno(dbInfos);
        }
        result.setFromDefault();
        return result;
    }
}

export class NodeTechno extends Techno{
    versionsAvailable = ['v10', 'v12', 'v14'];

}


export const defaultTechnos = new Map();


defaultTechnos.set(technos.CUSTOM, new Techno({
  id : technos.CUSTOM,
  back : false,
  front: true,
  name : 'custom',
  keywords: 'react front angular',
  displayInfo : {
      name : "Custom service",
      description : "Start a new custom service",
      picture: "custom"
  }
}))

defaultTechnos.set(technos.WEWEB, new Techno({
    id : technos.WEWEB,
    back : false,
    front: true,
    name : 'weweb',
    keywords: 'front',
    displayInfo : {
        name : "WeWeb",
        description : "NoCode Plateform - visual programming",
        picture: "weweb"
    }
}))

defaultTechnos.set(technos.XANO, new Techno({
  id : technos.XANO,
  back : true,
  front: false,
  name : 'xano',
  keywords: 'back api',
  displayInfo : {
      name : "Xano",
      description : "NoCode Plateform - visual programming",
      picture: "xano"
  }
}))

defaultTechnos.set(technos.PLASMIC, new Techno({
  id : technos.PLASMIC,
  state: technoState.NOT_AVAILABLE,
  back : false,
  front: true,
  name : 'plasmic',
  keywords: 'front react',
  displayInfo : {
      name : "plasmic",
      description : "Lowcode Plateform - visual programming",
      picture: "plasmic"
  }
}))

defaultTechnos.set(technos.DIRECTUS, new Techno({
  id : technos.DIRECTUS,
  back : true,
  front: true,
  state: technoState.NOT_AVAILABLE,
  name : 'directus',
  keywords: 'api back',
  displayInfo : {
      name : "Directus",
      description : "CMS",
      picture: "directus"
  }
}))

defaultTechnos.set(technos.N8N, new Techno({
  id : technos.N8N,
  back : true,
  front: false,
  state: technoState.NOT_AVAILABLE,
  name : 'n8n',
  keywords: 'flow automation',
  displayInfo : {
      name : "n8n",
      description : "Flow",
      picture: "n8n"
  }
}))

defaultTechnos.set(technos.SUPABASE, new Techno({
  id : technos.SUPABASE,
  back : true,
  front: false,
  state: technoState.PREVIEW,
  name : 'supabase',
  keywords: 'back api database',
  displayInfo : {
      name : "Supbase",
      description : "Backend",
      picture: "supabase"
  }
}))


defaultTechnos.set(technos.WEBFLOW, new Techno({
  id : technos.WEBFLOW,
  back : false,
  front: true,
  name : 'webflow',
  state: technoState.PREVIEW,
  displayInfo : {
      name : "Webflow",
      description : "Front",
      picture: "webflow"
  }
}))

defaultTechnos.set(technos.STRAPI, new Techno({
  id : technos.POSTGRESQL,
  back : true,
  front: true,
  name : 'strapi',
  state: technoState.PREVIEW,
  displayInfo : {
      name : "strapi",
      description : "CMS",
      picture: "strapi"
  }
}))

defaultTechnos.set(technos.POSTGRESQL, new Techno({
  id : technos.POSTGRESQL,
  back : true,
  front: false,
  excludeService: true,
  name : 'postgresql',
  displayInfo : {
      name : "postgresql",
      description : "Database",
      picture: "postgresql"
  }
}))
